<template>
  <div>
    <b-modal id="modal-1" title="Daftar Realisasi pendapatan Dana" ok-disabled no-close-on-backdrop hide-footer size="xl">
      <b-alert variant="primary" show>
        <div class="alert-body">
          <b-row>
            <b-col md="10">
              <h5 class="text-primary"><feather-icon icon="GridIcon" size='15' /> <strong class="ml-1">{{ rs_detail.kode_4
              }}
                  {{ rs_detail.uraian
                  }}</strong>
              </h5>
            </b-col>
            <b-col md="2">
              <b-badge variant="primary" v-b-modal.modal-2 style="float: right;"><feather-icon icon="PlusCircleIcon"
                  size='15' />
                Tambah pendapatan Dana</b-badge>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2"><small>Anggaran </small>
              <h5><strong><sup>Rp. </sup>{{ Rp(rs_detail.anggaran) }}</strong></h5>
            </b-col>
            <b-col md="2">
              <small>Realisasi Periode Lalu </small>
              <h5><strong><sup>Rp. </sup>{{ Rp(rs_detail.anggaran_lalu) }}</strong></h5>

            </b-col>
            <b-col md="2">
              <small>Realisai Periode Ini </small>
              <h5><strong><sup>Rp. </sup>{{ Rp(rs_detail.anggaran_ini) }}</strong></h5>
            </b-col>
            <b-col md="2">
              <small>Total Realisasi </small>
              <h5><strong><sup>Rp. </sup>{{ Rp(rs_detail.total) }}</strong></h5>
            </b-col>
            <b-col md="2">
              <small>Sisa Anggaran </small>
              <h5><strong><sup>Rp. </sup>{{ Rp(rs_detail.sisa) }}</strong></h5>
            </b-col>

            <b-col md="2">
              <small>Prosentase Resapamn </small>
              <h5><strong><sup>Rp. </sup> {{ rs_detail.prosentase_realisasi }}%</strong></h5>
            </b-col>
          </b-row>
        </div>
      </b-alert>
      <b-row>
        <b-col>
          <pendapatandanadaftar :rincian_pendapatan="rincian_pendapatan" @HapusData=HapusData></pendapatandanadaftar>
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BRow, BCol, BCard, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import pendapatandanadaftar from './pendapatanDanaDaftar.vue'
export default {
  components: {
    BButton,
    BModal, BBadge, pendapatandanadaftar,
    BAlert, BRow, BCol, BCard,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["HapusData"],
  methods: {
    HapusData(val) {

      this.$emit('HapusData', val)
    },
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    close() {
      this.$root.$emit('bv::hide::modal-1', 'AddLevel')
    }
  },
  props: {
    rs_detail: {},
    rincian_pendapatan: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>