<template>
  <b-row>

    <b-col md="6" sm="8" class="my-1">
    </b-col>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left  "></label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
    <b-col md="4" class="my-1">
      <b-form-group label="" abel-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
        </b-input-group>
      </b-form-group>
    </b-col>


    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(wbs)="data">
          <b-row>
            <b-col md="4">
              <h6 class="text-primary"> {{ data.item.kode_5 }} {{ data.item.uraian }}<br><small> Jenis Pembayaran {{
                data.item.jenis_pembayaran }}</small> </h6>
            </b-col>
            <b-col md="3">
              <h6 class="text-primary">{{ data.item.judul }} <br><small>{{ data.item.keterangan
              }}</small><br><small> Tgl pendapatan {{ data.item.tanggal_pendapatan }}</small> </h6>
            </b-col>
            <b-col md="4">
              <small>Total pendapatan </small>
              <h4><strong><sup>Rp. </sup>{{ Rp(data.item.anggaran) }}</strong></h4>
            </b-col>
            <b-col md="1">
              <b-badge variant="danger" size="sm" class="ml-1 mt-1" @click="HapusData(data.item)">
                <feather-icon icon="TrashIcon" size="15"></feather-icon>
              </b-badge>
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-col>

    <b-col cols="6">
      Total Data {{ totalRows }}
    </b-col>
    <b-col cols="6">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  props: {
    rincian_pendapatan: {},
    totalRowsa: {}
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],

      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],

    }
  },
  computed: {
    items: {
      get: function () {
        return this.rincian_pendapatan;
      },
      set: function (newValue) {
        return this.rincian_pendapatan = newValue;
      }
    },
    totalRows: {
      get: function () {
        return this.rincian_pendapatan.length;
      },
      set: function (newValue) {
        return this.totalRowss = newValue;
      }
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
  },
  mounted() {
    this.totalRows = this.items.length
  },
  emits: ['HapusData'],
  methods: {
    HapusData(val) {

      if (confirm("apakah anda yakin akan menghapus data ini...?")) {
        this.$emit('HapusData', val)
      }
    },
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.btn-block {
  display: block;
  width: 100%;
}
</style>