<template>
  <div>
    <div>
      <b-row class="text-center text-break  bg-h0">
        <b-col md="1" class=" pt-1"><strong class="mt-1">Kode</strong> </b-col>
        <b-col md="3" class=" pt-1"><strong>Uraian</strong></b-col>
        <b-col md="2" class=" pt-1"><strong>Tagget Pendapatan </strong></b-col>
        <b-col md="4" class="">
          <b-row>
            <b-col md="4" class=""><strong>Pendapatan <br>s/d Periode Lalu</strong></b-col>
            <b-col md="4" class=""><strong>Pendapatan <br>Periode Ini</strong></b-col>
            <b-col md="4" class=""><strong>Total <br>Pendapatan </strong></b-col>
          </b-row>
        </b-col>
        <b-col md="2" class=" pt-1"><strong>Lebih/Kurang</strong></b-col>
      </b-row>
    </div>

    <div v-for="objek in objek_pendapatan" v-bind:key="objek.kode_1">
      <b-row class="bg-h1">
        <b-col md="1" class=" ">{{ objek.kode_1 }} </b-col>
        <b-col md="3" class=" "> {{ objek.uraian }}</b-col>
        <b-col md="2" class=" "><strong style="float:right">{{ Rp(objek.anggaran) }}</strong></b-col>
        <b-col md="4" class="">
          <b-row>
            <b-col md="4" class=""><small style="float:right">{{ Rp(objek.anggaran_lalu) }}</small></b-col>
            <b-col md="4" class=""><small style="float:right">{{ Rp(objek.anggaran_ini) }}</small></b-col>
            <b-col md="4" class=""><small style="float:right">{{ Rp(objek.total) }}</small></b-col>
          </b-row>
        </b-col>
        <b-col md="2"><strong style="float:right">{{ Rp(objek.sisa) }}</strong></b-col>
      </b-row>
      <div v-for="kode2 in objek.kode_2" v-bind:key="kode2.id_kode_2">
        <b-row class="bg-h2">
          <b-col md="1" class=" ">{{ kode2.kode_2 }} </b-col>
          <b-col md="3" class=" "> {{ kode2.uraian }}</b-col>
          <b-col md="2" class=" "><strong style="float:right">{{ Rp(kode2.anggaran) }}</strong></b-col>
          <b-col md="4" class="">
            <b-row>
              <b-col md="4" class=""><small style="float:right">{{ Rp(kode2.anggaran_lalu) }}</small></b-col>
              <b-col md="4" class=""><small style="float:right">{{ Rp(kode2.anggaran_ini) }}</small></b-col>
              <b-col md="4" class=""><small style="float:right">{{ Rp(kode2.total) }}</small></b-col>
            </b-row>
          </b-col>
          <b-col md="2"><strong style="float:right">{{ Rp(kode2.sisa) }}</strong></b-col>
        </b-row>
        <div v-for="kode_3 in kode2.kode_3" v-bind:key="kode_3">
          <b-row class="bg-h3">
            <b-col md="1" class=" ">{{ kode_3.kode_3 }} </b-col>
            <b-col md="3" class=" "> {{ kode_3.uraian }}</b-col>
            <b-col md="2" class=" "><strong style="float:right">{{ Rp(kode_3.anggaran) }}</strong></b-col>
            <b-col md="4" class="">
              <b-row>
                <b-col md="4" class=""><small style="float:right">{{ Rp(kode_3.anggaran_lalu) }}</small></b-col>
                <b-col md="4" class=""><small style="float:right">{{ Rp(kode_3.anggaran_ini) }}</small></b-col>
                <b-col md="4" class=""><small style="float:right">{{ Rp(kode_3.total) }}</small></b-col>
              </b-row>
            </b-col>
            <b-col md="2"><strong style="float:right">{{ Rp(kode_3.sisa) }}</strong></b-col>
          </b-row>
          <div class=" " v-for="kode_4 in kode_3.kode_4" v-bind:key="kode_4">
            <b-row class="mt-1">
              <b-col md="1" class=" ">{{ kode_4.kode_4 }} </b-col>
              <b-col md="3" class=" "> {{ kode_4.uraian }}</b-col>
              <b-col md="2" class=" "><span style="float:right">{{ Rp(kode_4.anggaran) }}</span></b-col>
              <b-col md="4" class="">
                <b-row>
                  <b-col md="4" class=""><small style="float:right">{{ Rp(kode_4.anggaran_lalu) }}</small></b-col>
                  <b-col md="4" class=""><small style="float:right">{{ Rp(kode_4.anggaran_ini) }}</small></b-col>
                  <b-col md="4" class=""><small style="float:right">{{ Rp(kode_4.total) }}</small></b-col>
                </b-row>
              </b-col>
              <b-col md="2">
                <b-row>
                  <b-col md="9"><span style="float:right">{{ Rp(kode_4.sisa) }}</span> </b-col>
                  <b-col md="2"><b-badge variant="primary" v-b-modal.modal-1 @click="LoadDetail(kode_4)"><feather-icon
                        icon="PlusIcon" size='15' /></b-badge></b-col>
                </b-row>


              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab, BRow, BCol, BBadge, BButton
} from 'bootstrap-vue'
export default {
  components: {
    BTabs,
    BTab, BRow, BCol, BBadge, BButton
  },
  props: {
    objek_pendapatan: {}
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ['LoadDetail'],
  methods: {
    LoadDetail(val) {
      this.$emit('LoadDetail', val)
    },
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
  },
  computed: {

  },

}
</script>
<style scoped>
.groove {
  border: 1px solid #ebe9f1 !important;
}

.hrs {
  border: 0;
  border-top: 1px solid #ebe9f1;
}

.bg-h0 {
  background: #5d51e2 !important;
  padding: 5px;
  color: rgb(255, 255, 255);
}

.bg-h1 {
  background: #dfd7f5;
  padding: 5px;
  color: rgb(0, 0, 0);
}

.bg-h2 {
  background: #ece7fa;
  padding-top: 5px;
  color: rgb(0, 0, 0);
}

.bg-h3 {
  background: #f1edfc;
  padding-top: 5px;
  color: rgb(0, 0, 0);
}
</style>