import { render, staticRenderFns } from "./detailDinas.vue?vue&type=template&id=1bc7fce2&"
import script from "./detailDinas.vue?vue&type=script&lang=js&"
export * from "./detailDinas.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports